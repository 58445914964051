
  h2 {
    color: #404040; /* Replace with your desired text color */
    font-size: 18px; /* Adjust font size as needed */
    justify-content: flex-start;
  }

  .outlined-box {
    border: 1px solid #D3D3D3; /* Black outline */
    margin: 2px;
    border-radius: 12px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    padding-top: 4px;
    margin-bottom: 16px;
    width: 100%;
    
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    gap: 4px; /* Adds space between cards */
  }

  
  